import { IPaginationInfo } from '../search/pagination.D';

export enum orgNames {
  'jigzo' = 'Jigzo',
  'blockfx' = 'BlockFX',
  'creditum' = 'Creditum',
}

export enum orgTypes {
  NEO,
  DAX,
  master,
}

export interface IOrganization {
  _id: string;
  logo: string;
  name: string;
  host: string;
  description: string;
  type: orgTypes;
  platformURL: string;
  shortedName: string;
  isDeployed: boolean;
  isUpdated: boolean;
  marketingURL?: string;
  upcomingHost?: string;
}
export type IOrganizationCreate = Omit<IOrganization, '_id'>;

export interface IOrganizationSlice {
  organizations: {
    items: IOrganization[];
    info: IPaginationInfo;
  };
  organizationList: IOrganization[];
  allOrganizationsList: IOrganization[];
  organization: IOrganization | null;
  isLoading: boolean;
  isHostLoading: boolean;
  subOrgChanged: boolean;
  currentSubOrg?: IOrganization;
}

export enum HostsNameEnum {
  client = 'client',
  admin = 'master',
}

export interface IQueriesOrganization {
  [key: string]: string;
}

export interface IOrganizationsByAdmin {
  queries: IQueriesOrganization;
  id: string;
}
