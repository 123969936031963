// CORE
import { ReactElement } from 'react';
import { Redirect, Route } from 'react-router-dom';
// HOOKS
import { useAppDispatch, useAppSelector } from '../hooks/redux.hook';
// TYPES
import { IRoutes } from '../routes/routes.D';
// STORE
import adminStore from '../store/reducers/admin';
import { getRefreshToken } from '../../app/helpers/localStorage';

const PrivateRoutes = (route: IRoutes): ReactElement | null => {
  const isLoading = useAppSelector(({ admin }) => admin?.isLoading);
  const isHostLoading = useAppSelector(state => state.organizations.isHostLoading);
  const isAuth = useAppSelector(({ admin }) => admin?.adminAuthed);
  const token = getRefreshToken();
  const dispatch = useAppDispatch();

  if (!token) {
    dispatch(adminStore.actions.clearAdmin());

    return <Redirect to={{ pathname: '/' }} />;
  }

  if (isLoading || isHostLoading) return null;

  if (!isAuth && route.private) return <Redirect to={{ pathname: '/' }} />;

  if (route.redirectPath) return <Redirect to={{ pathname: route.redirectPath }} />;

  if (!isAuth && !route.private)
    return (
      <Route
        path={route.path}
        key={route.path}
        render={props => (
          <route.layout>
            <route.component {...props} />
          </route.layout>
        )}
      />
    );

  return (
    <Route
      path={route.path}
      key={route.path}
      render={props => (
        <route.layout>
          <route.component {...props} />
        </route.layout>
      )}
    />
  );
};

export default PrivateRoutes;
