// CORE
import { combineReducers } from '@reduxjs/toolkit';
// REDUCERS
import auth from './auth';
import apiKeys from './apiKeys';
import notifier from './notifier';
import customers from './customers';
import admin from './admin';
import customer from './customer';
import transactions from './transactions';
import transfers from './transfers';
import cards from './cards';
import beneficiaries from './beneficiaries';
import documents from './documents';
import admins from './admins';
import beneficiary from './beneficiary';
import roles from './roles';
import balances from './balances';
import riskAlerts from './riskAlerts';
import riskAlert from './riskAlert';
import manageCompliance from './manageCompliance';
import manageComplianceDetails from './manageComplianceDetails';
import currencies from './currencies';
import countries from './countries';
import margins from './margins';
import fees from './fees';
import organizations from './organizations';
import reports from './reports';
import products from './products';
import payTiers from './payTiers';
import notes from './notes';
import proofs from './proofs';
import business from './business';
import logs from './logs';
import limits from './limits';
import maintenanceFees from './maintenanceFees';
import balanceFees from './balanceFees';
import regularFees from './regularFee';
import productsCrypto from './productsCrypto';
import cryptoFeesMargins from './cryptoFeesMargins';
import digitalAssets from './digitalAssets';
import pooledAccounts from './pooledAccounts';
import externalAssets from './externalAssets';
import transactionsCrypto from './transactionsCrypto';
import dashboard from './dashboard';
import forecasting from './forecasting';
import batchedTransfers from './batchedTransfers';
import batchedTransfer from './batchedTransfer';
import highRiskTransactions from './highRiskTransactions';
import forecastingDax from './forecastingDax';
import bankDetails from './bankDetails';
import manualTransactions from './manualTransactions';
import clients from './clients';
import batchedTransfersLogs from './batchedTransferLogs';
import settings from './settings';
import organizationSettings from './organizationSettings';
import jurisdictions from './jurisdictions';
import spVerifications from './spVerifications';
import waitList from './waitList';
import riskToleranceSettings from './riskToleranceSettings';
import pricePlans from './pricePlans';
import businessActivity from './businessActivity';
import registrationRequests from './registrationRequests';
import freeMarket from './freeMarket/index';
import basePriceTiers from './basePriceTiers';
import marginsFees from './marginsFees';
import transactionsCosts from './transactionsCosts';
import questionnaire from './questionnaire';
import announcements from './announcements';
import digitalAssetNetworks from './digitalAssetNetworks';
import fonts from './fonts';

export const rootReducer = combineReducers({
  admin: admin.reducer,
  admins: admins.reducer,
  apiKeys: apiKeys.reducer,
  auth: auth.reducer,
  notifier: notifier.reducer,
  customers: customers.reducer,
  customer: customer.reducer,
  transactions: transactions.reducer,
  transfers: transfers.reducer,
  beneficiaries: beneficiaries.reducer,
  beneficiary: beneficiary.reducer,
  documents: documents.reducer,
  roles: roles.reducer,
  balances: balances.reducer,
  riskAlerts: riskAlerts.reducer,
  riskAlert: riskAlert.reducer,
  manageCompliance: manageCompliance.reducer,
  cards: cards.reducer,
  manageComplianceDetails: manageComplianceDetails.reducer,
  currencies: currencies.reducer,
  countries: countries.reducer,
  margins: margins.reducer,
  fees: fees.reducer,
  organizations: organizations.reducer,
  reports: reports.reducer,
  products: products.reducer,
  payTiers: payTiers.reducer,
  notes: notes.reducer,
  business: business.reducer,
  proofs: proofs.reducer,
  logs: logs.reducer,
  limits: limits.reducer,
  maintenanceFees: maintenanceFees.reducer,
  balanceFees: balanceFees.reducer,
  regularFees: regularFees.reducer,
  productsCrypto: productsCrypto.reducer,
  cryptoFeesMargins: cryptoFeesMargins.reducer,
  digitalAssets: digitalAssets.reducer,
  digitalAssetNetworks: digitalAssetNetworks.reducer,
  pooledAccounts: pooledAccounts.reducer,
  externalAssets: externalAssets.reducer,
  transactionsCrypto: transactionsCrypto.reducer,
  dashboard: dashboard.reducer,
  forecasting: forecasting.reducer,
  batchedTransfers: batchedTransfers.reducer,
  batchedTransfer: batchedTransfer.reducer,
  highRiskTransactions: highRiskTransactions.reducer,
  forecastingDax: forecastingDax.reducer,
  bankDetails: bankDetails.reducer,
  manualTransactions: manualTransactions.reducer,
  clients: clients.reducer,
  batchedTransfersLogs: batchedTransfersLogs.reducer,
  settings: settings.reducer,
  organizationSettings: organizationSettings.reducer,
  jurisdictions: jurisdictions.reducer,
  spVerifications: spVerifications.reducer,
  waitList: waitList.reducer,
  riskToleranceSettings: riskToleranceSettings.reducer,
  pricePlans: pricePlans.reducer,
  businessActivity: businessActivity.reducer,
  registrationRequests: registrationRequests.reducer,
  freeMarket: freeMarket.reducer,
  basePriceTiers: basePriceTiers.reducer,
  marginsFees: marginsFees.reducer,
  transactionsCosts: transactionsCosts.reducer,
  questionnaire: questionnaire.reducer,
  announcements: announcements.reducer,
  fonts: fonts.reducer,
});
