// API
import { apiClient } from '../../clients/api.client';
// HELPERS
import getBrand from '../../helpers/getBrand';
// TYPES
import { IDigitalAssetNetwork } from 'app/store/reducers/digitalAssetNetworks/index.D';

const { get, post } = apiClient;
const URL = () => `organizations/${getBrand(true)}/crypto-networks`;

export default {
  getAllAssetNetworks: (id: string) => get(`${URL()}/${id}`),
  editDigitalAssets: (data: Partial<IDigitalAssetNetwork>) => post(`${URL()}/${data._id}`, data),
};
